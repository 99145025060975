.App-header {
  min-height: 100vh;
  color: white;
}

header,
footer {
  background-color: #374153;
  text-align: center;
  color: white;
  padding: 10px 0;
}

header {
  /* margin-bottom: 50px; */
  font-size: 28px;
}

footer {
  margin-top: 50px;
  font-size: 14px;
}

.card {
  margin: 10px 0;
}

a,
a:hover {
  color: #282c34;
  text-decoration: none;
}

video {
  width: 100%;
  height: 50vh;
}

img {
  height: 200px;
  object-fit: cover;
  object-position: center top;
}

p {
  margin-bottom: 5px;
  font-size: 16px;
}
